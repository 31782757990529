export const onRouteUpdate = async ({ location }) => {
  if (!window.analytics || typeof window.analytics.page !== "function") {
    console.warn("Unable to locate analytics.js")

    return
  }

  const { language, userAgent } = window.navigator
  const { name } = location
  const properties = {
    language,
    userAgent
  }

  return window.analytics.page(name, { properties })
}