exports.components = {
  "component---src-layouts-blog-post-js": () => import("./../../../src/layouts/blog-post.js" /* webpackChunkName: "component---src-layouts-blog-post-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-clientes-tsx": () => import("./../../../src/pages/clientes.tsx" /* webpackChunkName: "component---src-pages-clientes-tsx" */),
  "component---src-pages-customers-mireyafavela-emmanuel-4-mo-tsx": () => import("./../../../src/pages/customers/mireyafavela/emmanuel4mo.tsx" /* webpackChunkName: "component---src-pages-customers-mireyafavela-emmanuel-4-mo-tsx" */),
  "component---src-pages-customers-mireyafavela-emmanuel-5-mo-tsx": () => import("./../../../src/pages/customers/mireyafavela/emmanuel5mo.tsx" /* webpackChunkName: "component---src-pages-customers-mireyafavela-emmanuel-5-mo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-paquetes-tsx": () => import("./../../../src/pages/paquetes.tsx" /* webpackChunkName: "component---src-pages-paquetes-tsx" */)
}

